import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { myLoginService } from '@/services/login.service';
import includes from 'lodash/includes';

interface UserRoleProps {
    user: string;
    children: React.ReactNode;
}

const UserRole: React.FC<UserRoleProps> = ({ user, children }) => {
    const userRoleUrl = myLoginService.getUserRoleUrl();
    const gigyaToken = myLoginService.getTokenData();
    const navigate = useNavigate();

    useEffect(() => {
        const token = myLoginService.getTokenData();
        if (!token) {
            navigate('/');
            return;
        }else if(gigyaToken && token && userRoleUrl==''){
            navigate('/home');
            return;
        }

        if (includes(userRoleUrl, user)) {
            if (user === '/addtional-info') {
                const queryParams = new URLSearchParams(window.location.search);
                const loginEmail = queryParams.get('loginEmail');
                const absoluteUrl = new URL(userRoleUrl, window.location.origin);
                const userRoleEmail = absoluteUrl.searchParams.get('loginEmail') || '';
                if (loginEmail !== userRoleEmail) {
                    navigate(myLoginService.getUserRoleUrl());
                }
            }
        } else {
            navigate(userRoleUrl);
        }
    }, [user, userRoleUrl, navigate]);

    return <>{children}</>;
};

export default UserRole;