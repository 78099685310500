import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import get from 'lodash/get';
import includes from 'lodash/includes';
import toUpper from 'lodash/toUpper';
import { myLoginService } from '@/services/login.service';
import { country } from '../utils/env';
import { errorCode, errorResCode } from '@/core/utils';
import baseUrl from '../../services';
import { user } from '@/services/user.service';

enum StatusCode {
	Unauthorized = 401,
	Forbidden = 403,
	TooManyRequests = 429,
	InternalServerError = 500,
	ServiceUnavailable = 503,
	GatewayTimeOut = 502,
	NoToken = 499,
	TimeoutToken = 498,
}

const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: '',
    'Cache-Control': 'no-cache, no-store, must-revalidate',  
    'Strict-Transport-Security': 'max-age=86400', 
    'X-Content-Type-Options': 'nosniff', 
};

const fileUploadheaders: Readonly<Record<string, string | boolean>> = {
	'Content-Type': 'multipart/form-data',
};

// We can use the following function to inject the JWT token through an interceptor
// We get the `accessToken` from the localStorage that we set when we authenticate
const injectToken: any = (
	config: AxiosRequestConfig,
	dpToken?: string
): AxiosRequestConfig => {
	myLoginService.setTokenType(myLoginService.getTokenType() || '');
	const type = myLoginService.getTokenType();
	const headers: any = {
		authorizationType: type,
		apiVersion: 'V2',
		Authorization: '',
		country: user.getRegion() || 'JP',
		language: user.getLanguage(),
	};

	try {
		const url = get(config, 'url');
		if (url === '/api/auth/verifyCode' || url === 'api/auth/refresh/token') {
			return config;
		} else {
			if (dpToken) {
				headers.Authorization = `Bearer ${dpToken}`;
			} else {
				const token = myLoginService.getTokenData();
				headers.Authorization = `Bearer ${token}`;
			}
		}

		// Use spread operator to merge headers with existing config.headers
		config.headers = { ...config.headers, ...headers };
		return config;
	} catch (error) {
		return config;
	}
};

class Http {
	private instance: AxiosInstance | null = null;

	private get http(): AxiosInstance {
		return this.instance != null ? this.instance : this.initHttp();
	}

	initHttp() {
		const http = axios.create({
			baseURL: baseUrl,
			headers,
		});

		// add country in the header
		http.defaults.headers.common['Country'] = toUpper(country());

		http.interceptors.request.use(injectToken, (error) =>
			Promise.reject(error)
		);

		http.interceptors.response.use(
			(response) => {
				const type = myLoginService.getTokenType();
				if (type === 'JWT') {
					const dpToken = get(response?.data, 'dpToken');
					if (dpToken) {
						myLoginService.setTokenData(dpToken);
					}
					injectToken(response.config, dpToken);
				}
				if (response?.status == 200) {
					const code = get(get(response, 'data'), 'code');
					user.setUsercode(code || '');
					if (includes(errorCode, code)) {
						errorResCode(code);
					}
					return response.data;
				}
				return response.data;
			},
			(error) => {
				if (error.response) {
					const { status } = error.response;
					this.handleError(status);
				}
				return Promise.reject(error);
			}
		);

		this.instance = http;
		return http;
	}

	request<T = any, R = AxiosResponse<T>>(
		config: AxiosRequestConfig
	): Promise<R> {
		return this.http.request(config);
	}

	get<T = any, R = AxiosResponse<T>>(
		url: string,
		config?: AxiosRequestConfig
	): Promise<R> {
		return this.http.get<T, R>(url, config);
	}

	post<T = any, R = AxiosResponse<T>>(
		url: string,
		data?: T,
		config?: AxiosRequestConfig
	): Promise<R> {
		return this.http.post<T, R>(url, data, config);
	}

	put<T = any, R = AxiosResponse<T>>(
		url: string,
		data?: T,
		config?: AxiosRequestConfig
	): Promise<R> {
		return this.http.put<T, R>(url, data, config);
	}

	delete<T = any, R = AxiosResponse<T>>(
		url: string,
		config?: AxiosRequestConfig
	): Promise<R> {
		return this.http.delete<T, R>(url, config);
	}

	fileUpload<T = any, R = AxiosResponse<T>>(
		url: string,
		data?: T,
		config?: AxiosRequestConfig
	): Promise<R> {
		return this.http.post<T, R>(url, data, {
			...config,
			headers: fileUploadheaders,
			responseType: config?.responseType ? config.responseType : 'blob',
		});
	}

	fileDownload<T = any, R = AxiosResponse<T>>(
		url: string,
		data?: T,
		config?: AxiosRequestConfig
	): Promise<R> {
		return this.http.post<T, R>(url, data, {
			...config,
			responseType: config?.responseType ? config.responseType : 'blob',
		});
	}

	// Handle global app errors
	// We can handle generic app errors depending on the status code
	private handleError(status: string | number) {
		console.log('handleError==============:', status);
		switch (status) {
			case StatusCode.InternalServerError: {
				// Handle InternalServerError
				break;
			}
			case StatusCode.Forbidden: {
				// Handle Forbidden
				break;
			}
			case StatusCode.Unauthorized: {
				errorResCode(401);
				break;
			}
			case StatusCode.TooManyRequests: {
				// Handle TooManyRequests
				break;
			}
			case StatusCode.NoToken: {
				errorResCode(499);
				break;
			}
			case StatusCode.TimeoutToken: {
				errorResCode(498);
				break;
			}
			default:
				return;
		}
	}
}

export interface ResponsePageType {
	pageNo: number;
	pageSize: number;
	count: number;
}

export interface ResponseBaseType<T> {
	message: string;
	data: T;
	code: string;
	page: ResponsePageType;
}

export const http = new Http();
